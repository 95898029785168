import React, {useState , useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, Grid, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import moment from 'moment'
import 'moment/locale/th';

const str = new Date().toLocaleDateString('th', { timeZone: 'Africa/Cairo' });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
    

export default function Goverment() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#5D8BF4',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


    const handleSubmit = event =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "p_date": p_date,
          "p_one": p_one,
          "p_threefront": p_threefront,
          "p_threeback": p_threeback,
          "p_threetop": p_threetop,
          "p_twotop": p_twotop,
          "p_twobottom": p_twobottom,
          "lotto_id": "TH",
          "lotto_name": "THGV"
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
    
    fetch("https://baanpholhuayapi.herokuapp.com/goverment/add", requestOptions)
      .then(response => response.json())
      .then(result => {
        alert(result['status'])
          if (result['status'] === 'OK'){
            window.location.href ='/goverment'
          }
      })
      .catch(error => console.log('error', error));
      }
      const [p_date , setP_date] = useState('')
      const [p_one , setP_one] = useState('')
      const [p_threefront , setP_threefront] = useState('')
      const [p_threeback , setP_threeback] = useState('')
      const [p_threetop , setP_threetop] = useState('')
      const [p_twotop , setP_twotop] = useState('')
      const [p_twobottom , setP_twobottom] = useState('')
      const [lotto_id , setLotto_id] = useState('')
      const [lotto_name , setLotto_name] = useState('')
    
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetch("https://baanpholhuayapi.herokuapp.com/ruam/hun")
          .then(res => res.json())
          .then(
            (result) => {
              setItems(result);
            },
          )
      }, [])
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ p: 3 }}>
          <Paper sx={ {p:2 ,bgcolor: '#06113C'}}>
          <Box display="flex">
                <Box sx={{ flexGrow: 1,bgcolor: '#FEB139' }}>
                <Typography sx={ {p:1}} variant="h6" gutterBottom component="div">
                🌟 ผลหวยหุ้น / หวยหุ้น VIP ประจำวันที่ {str}
                    </Typography>
                </Box>
                    <Box>
                    <Box sx={{ flexGrow: 1 }}>
                    
                        </Box>
                </Box>
          </Box>
          <TableContainer component={Paper}>
              
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <StyledTableCell align="center">หวย</StyledTableCell>
            <StyledTableCell align="center">งวดวันที่</StyledTableCell>
            <StyledTableCell align="center">3 ตัวบน</StyledTableCell>
            <StyledTableCell align="center">2 ตัวล่าง</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell align="center">{row.lotto}</StyledTableCell>
              <StyledTableCell align="center">{moment(row.p_date).add(543,'y').format('Do MMMM YYYY')}</StyledTableCell>
              <StyledTableCell align="center">{row.p_threetop}</StyledTableCell>
              <StyledTableCell align="center">{row.p_twobottom}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>   
          </Paper>
          <Stack>
          <Box sx={{ width: '100%',bgcolor: '#FEB139' }} >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 3 }}>
        <Grid item xs={12}>
          <Item  sx={{ bgcolor: '#06113C' }}>
          <Button href="/nikeimor" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นนิเคอิเช้า ย้อนหลัง
        </Button>
        <Button href="/chinamor" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นจีนเช้า ย้อนหลัง
        </Button>
        <Button href="/hungmor" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นฮั่งเส็ง เช้า ย้อนหลัง
        </Button>
        <Button href="/taiwan" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นไต้หวัน ย้อนหลัง
        </Button>
        <Button href="/nikeiaf" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นนิเคอิ บ่าย ย้อนหลัง
        </Button>
        <Button href="/korea" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นเกาหลี ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
        <Button href="/chinaaf" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นจีนบ่าย ย้อนหลัง
        </Button>
        <Button href="/hungaf" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นฮั่งเส็ง บ่าย ย้อนหลัง
        </Button>
        <Button href="/singapore" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นสิงคโปร์ ย้อนหลัง
        </Button>
        <Button href="/thai" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นไทย ย้อนหลัง
        </Button>
        <Button href="/india" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นอินเดีย ย้อนหลัง
        </Button>
        <Button href="/egypt" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นอิยิปต์ ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
          <Button href="/russia" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นรัสเซีย ย้อนหลัง
        </Button>
        <Button href="/england" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นอังกฤษ ย้อนหลัง
        </Button>
        <Button href="/german" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นเยอรมัน ย้อนหลัง
        </Button>
        <Button href="/dowjones" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นดาวโจนส์ ย้อนหลัง
        </Button>
        <Button href="/dowjonesvip" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นดาวโจนส์ VIP ย้อนหลัง
        </Button>
        <Button  href="/englandvip" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นอังกฤษ VIP ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
          <Button href="/germanvip" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นเยอรมัน VIP ย้อนหลัง
        </Button>
        <Button  href="/russiavip" variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นรัสเซีย VIP ย้อนหลัง
        </Button>
        <Button   href="/nikeimorvip" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นนิเคอิเช้า VIP ย้อนหลัง
        </Button>
        <Button href="/nikeiafvip" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นนิเคอิบ่าย VIP ย้อนหลัง
        </Button>
        <Button  href="/russiavip"  variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นจีนเช้า VIP ย้อนหลัง
        </Button>
        <Button  href="/chinaafvip"  variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นจีนบ่าย VIP ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
          <Button  href="/hungmorvip"  variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นฮั่งเส็งเช้า VIP ย้อนหลัง 
        </Button>
        <Button  href="/hungafvip"  variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นฮั่งเส็งบ่าย VIP ย้อนหลัง 
        </Button>
        <Button  href="/taiwanvip" variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นไต้หวัน VIP ย้อนหลัง 
        </Button>
        <Button  href="/koreavip"  variant="contained" color="primary">
        ตรวจสอบผลหวยหุ้นเกาหลี VIP ย้อนหลัง 
        </Button>
        <Button  href="/singaporevip"  variant="contained" color="secondary">
        ตรวจสอบผลหวยหุ้นสิงคโปร์ VIP ย้อนหลัง 
        </Button>
          </Item>
        </Grid>
      </Grid>
    </Box>
          </Stack>
          
      </Container>
    </React.Fragment>
  );
}
