import React, {useState , useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, Grid, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import moment from 'moment'
import 'moment/locale/th';

const str = new Date().toLocaleDateString('th', { timeZone: 'Africa/Cairo' });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
    

export default function Goverment() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#5D8BF4',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


    const handleSubmit = event =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "p_date": p_date,
          "p_one": p_one,
          "p_threefront": p_threefront,
          "p_threeback": p_threeback,
          "p_threetop": p_threetop,
          "p_twotop": p_twotop,
          "p_twobottom": p_twobottom,
          "lotto_id": "TH",
          "lotto_name": "THGV"
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
    
    fetch("https://baanpholhuayapi.herokuapp.com/goverment/add", requestOptions)
      .then(response => response.json())
      .then(result => {
        alert(result['status'])
          if (result['status'] === 'OK'){
            window.location.href ='/goverment'
          }
      })
      .catch(error => console.log('error', error));
      }
      const [p_date , setP_date] = useState('')
      const [p_one , setP_one] = useState('')
      const [p_threefront , setP_threefront] = useState('')
      const [p_threeback , setP_threeback] = useState('')
      const [p_threetop , setP_threetop] = useState('')
      const [p_twotop , setP_twotop] = useState('')
      const [p_twobottom , setP_twobottom] = useState('')
      const [lotto_id , setLotto_id] = useState('')
      const [lotto_name , setLotto_name] = useState('')
    
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetch("https://baanpholhuayapi.herokuapp.com/ruam/tang")
          .then(res => res.json())
          .then(
            (result) => {
              setItems(result);
            },
          )
      }, [])
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ p: 3 }}>
          <Paper sx={ {p:2 ,bgcolor: '#06113C'}}>
          <Box display="flex">
                <Box sx={{ flexGrow: 1,bgcolor: '#FEB139' }}>
                <Typography sx={ {p:1}} variant="h6" gutterBottom component="div">
                🌟ผลหวยลาว , หวยเวียดนาม , หวยมาเลเซีย ประจำวันที่ {str}
                    </Typography>
                </Box>
                    <Box>
                    <Box sx={{ flexGrow: 1 }}>
                    
                        </Box>
                </Box>
          </Box>
          <TableContainer component={Paper}>
              
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <StyledTableCell align="center">หวย</StyledTableCell>
            <StyledTableCell align="center">งวดวันที่</StyledTableCell>
            <StyledTableCell align="center">3 ตัวบน</StyledTableCell>
            <StyledTableCell align="center">2 ตัวล่าง</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell align="center">{row.lotto}</StyledTableCell>
              <StyledTableCell align="center">{moment(row.p_date).add(543,'y').format('Do MMMM YYYY')}</StyledTableCell>
              <StyledTableCell align="center">{row.p_threetop}</StyledTableCell>
              <StyledTableCell align="center">{row.p_twobottom}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>   
          </Paper>
          <Stack>
          <Box sx={{ width: '100%',bgcolor: '#FEB139' }} >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 3 }}>
        <Grid item xs={12}>
          <Item  sx={{ bgcolor: '#06113C' }}>
          <Button  href="/vtpi"  variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยพิเศษ ย้อนหลัง
        </Button>
        <Button  href="/vtsim" variant="contained" color="secondary">
        ตรวจสอบผลหวยฮานอยปกติ ย้อนหลัง
        </Button>
        <Button  href="/vtvip" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยVIP ย้อนหลัง
        </Button>
        <Button href="/vtstars" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยสตาร์ ย้อนหลัง
        </Button>
        <Button href="/vttv" variant="contained" color="secondary">
        ตรวจสอบผลหวยฮานอยTV ย้อนหลัง
        </Button>
        <Button href="/vtredcross" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยกาชาด ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
        <Button href="/vthd" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยHD ย้อนหลัง
        </Button>
        <Button href="/vtchn" variant="contained" color="secondary">
        ตรวจสอบผลหวยฮานอยตรุษจีน ย้อนหลัง
        </Button>
        <Button href="/vtdev" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยพัฒนาย้อนหลัง
        </Button>
        <Button href="/vtsamac" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยสามัคคี ย้อนหลัง
        </Button>
        <Button href="/vtnight"  variant="contained" color="secondary">
        ตรวจสอบผลหวยฮานอยดึก ย้อนหลัง
        </Button>
        <Button href="/vtextra" variant="contained" color="primary">
        ตรวจสอบผลหวยฮานอยExtra ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
          <Button href='/laosdev' variant="contained" color="primary">
        ตรวจสอบผลหวยลาวพัฒนา ย้อนหลัง
        </Button>
        <Button href='/laoextra' variant="contained" color="primary">
        ตรวจสอบผลหวยลาว EXTRA
        </Button>
        <Button href='/laostv' variant="contained" color="secondary">
        ตรวจสอบผลหวยลาวทีวี ย้อนหลัง
        </Button>
        <Button href='/laosspecial'  variant="contained" color="primary">
        ตรวจสอบผลหวยลาวพิเศษ ย้อนหลัง
        </Button>
        <Button href='/laoshd' variant="contained" color="primary">
        ตรวจสอบผลหวยลาว HD ย้อนหลัง
        </Button>
        <Button href='/laostars' variant="contained" color="secondary">
        ตรวจสอบผลหวยลาวสตาร์ ย้อนหลัง
        </Button>
        <Button href='/laochampasak' variant="contained" color="primary">
        ตรวจสอบผลหวยลาวจำปาสัก ย้อนหลัง
        </Button>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ bgcolor: '#06113C' }}>
          <Button href="/laosamac" variant="contained" color="primary">
        ตรวจสอบผลหวยลาวสามัคคี ย้อนหลัง
        </Button>
        <Button href="/laovip" variant="contained" color="secondary">
        ตรวจสอบผลลาว VIP ย้อนหลัง
        </Button>
        <Button href="/laostarsvip" variant="contained" color="primary">
        ตรวจสอบผลหวยลาวสตาร์VIP ย้อนหลัง
        </Button>
        <Button href="/laoviangjan" variant="contained" color="primary">
        ตรวจสอบผลหวยลาวเวียงจันทน์ ย้อนหลัง
        </Button>
        <Button href="/laogachad" variant="contained" color="secondary">
        ตรวจสอบผลหวยลาวกาชาด ย้อนหลัง
        </Button>
        <Button href="/malay" variant="contained" color="primary">
        ตรวจสอบผลหวยมาเลเซีย ย้อนหลัง
        </Button>
          </Item>
        </Grid>
      </Grid>
    </Box>
          </Stack>
          
      </Container>
    </React.Fragment>
  );
}
