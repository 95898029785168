import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter  ,Routes, Route  } from "react-router-dom";
import { Switch } from "react-router-dom"
import Nav from './components/Nav'
import Appp from './App copy'
import Front from './Front'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Footer from './components/Footer'
import Goverment from './components/Goverment1'
import Gsb3y from './components/Gsb3y'
import Gsb5y from './components/Gsb5y'
import Malay from './components/Malay'
import Laosdev from './components/laosdev'
import Laostv from './components/laostv'
import Laosspecial from './components/laosspecial'
import Laoshd from './components/laoshd'
import Laostars from './components/laostars'
import Laochampasak from './components/laochampasak'
import Laosamac from './components/laosamac'
import Laovip from './components/laovip'
import Laostarsvip from './components/laostarsvip'
import Laoviangjan from './components/laoviangjan'
import Laogachad from './components/laogachad'
import Vttv from './components/vttv'
import Vtpi from './components/vtpi'
import Vtsim from './components/vtsim'
import Vtvip from './components/vtvip'
import Vtstars from './components/vtstars'
import Vtredcross from './components/vtredcross'
import Vtsamac from './components/vtsamac'
import Vthd from './components/vthd'
import Vtchn from './components/vtchn'
import Vtdev from './components/vtdev'
import Vtnight from './components/vtnight'
import Vtextra from './components/vtextra'
import Nikeimor from './components/nikeimor'
import Chinaaf from './components/chinaaf'
import Chinamor from './components/chinamor'
import Dowjones from './components/dowjones'
import Egypt from './components/egypt'
import England from './components/england'
import German from './components/german'
import Hungaf from './components/hungaf'
import India from './components/india'
import Hungmor from './components/hungmor'
import Korea from './components/korea'
import Nikeiaf from './components/nikeiaf'
import Russia from './components/russia'
import Singapore from './components/singapore'
import Taiwan from './components/taiwan'
import Thai from './components/thai'
import Laoextra from './components/laosextra'
import Chinaafvip from './components/chinaafvip'
import Chinamorvip from './components/chinamorvip'
import Dowjonesvip from './components/dowjonesvip'
import Englandvip from './components/englandvip'
import Germanvip from './components/germanvip'
import Hungafvip from './components/hungafvip'
import Hungmorvip from './components/hungmorvip'
import Koreavip from './components/koreavip'
import Nikeiafvip from './components/nikeiafvip'
import Nikemorvip from './components/nikeimorvip'
import Russiavip from './components/russiavip'
import Singaporevip from './components/singaporevip'
import Taiwanvip from './components/taiwanvip'
import Ruamthai from './components/Ruamlotto'
import Ruamtang from './components/Ruamtang'
import Ruamhun from './components/Ruamhun'
import Toppost from './components/Toppost'
import Ruam from './components/Ruam'



ReactDOM.render(
  <React.StrictMode>
    <Nav/>
    <Toppost/>
    <BrowserRouter basename='/' >
    <Routes>
    <Route path="/" element={<App />} />
    <Route path="/ruam" element={<Ruam />} />
    <Route path="/goverment" element={<Goverment />} />
    <Route path="/gsb3y" element={<Gsb3y />} />
    <Route path="/gsb5y" element={<Gsb5y />} />
    <Route path="/malay" element={<Malay />} />
    <Route path="/laosdev" element={<Laosdev />} />
    <Route path="/laostv" element={<Laostv />} />
    <Route path="/laosspecial" element={<Laosspecial />} />
    <Route path="/laoshd" element={<Laoshd />} />
    <Route path="/laostars" element={<Laostars />} />
    <Route path="/laochampasak" element={<Laochampasak />} />
    <Route path="/laosamac" element={<Laosamac />} />
    <Route path="/laovip" element={<Laovip  />} />
    <Route path="/laostarsvip" element={<Laostarsvip />} />
    <Route path="/laoviangjan" element={<Laoviangjan />} />
    <Route path="/laogachad" element={<Laogachad />} />
    <Route path="/laoextra" element={<Laoextra />} />
    <Route path="/vttv" element={<Vttv />} />
    <Route path="/vtpi" element={<Vtpi />} />
    <Route path="/vtsim" element={<Vtsim />} />
    <Route path="/vtvip" element={<Vtvip />} />
    <Route path="/vtstars" element={<Vtstars />} />
    <Route path="/vtredcross" element={<Vtredcross />} />
    <Route path="/vtsamac" element={<Vtsamac />} />
    <Route path="/vthd" element={<Vthd />} />
    <Route path="/vtchn" element={<Vtchn />} />
    <Route path="/vtdev" element={<Vtdev />} />
    <Route path="/vtnight" element={<Vtnight />} />
    <Route path="/vtextra" element={<Vtextra />} />
    <Route path="/nikeimor" element={<Nikeimor />} />
    <Route path="/chinaaf" element={<Chinaaf />} />
    <Route path="/chinamor" element={<Chinamor />} />
    <Route path="/dowjones" element={<Dowjones />} />
    <Route path="/egypt" element={<Egypt />} />
    <Route path="/england" element={<England />} />
    <Route path="/german" element={<German />} />
    <Route path="/hungaf" element={<Hungaf />} />
    <Route path="/india" element={<India />} />
    <Route path="/hungmor" element={<Hungmor />} />
    <Route path="/korea" element={<Korea />} />
    <Route path="/nikeiaf" element={<Nikeiaf />} />
    <Route path="/russia" element={<Russia />} />
    <Route path="/singapore" element={<Singapore />} />
    <Route path="/taiwan" element={<Taiwan />} />
    <Route path="/thai" element={<Thai />} />
    <Route path="/chinaafvip" element={<Chinaafvip />} />
    <Route path="/chinamorvip" element={<Chinamorvip />} />
    <Route path="/dowjonesvip" element={<Dowjonesvip />} />
    <Route path="/englandvip" element={<Englandvip />} />
    <Route path="/germanvip" element={<Germanvip />} />
    <Route path="/hungafvip" element={<Hungafvip />} />
    <Route path="/hungmorvip" element={<Hungmorvip />} />
    <Route path="/koreavip" element={<Koreavip />} />
    <Route path="/nikeiafvip" element={<Nikeiafvip />} />
    <Route path="/nikeimorvip" element={<Nikemorvip />} />
    <Route path="/russiavip" element={<Russiavip />} />
    <Route path="/singaporevip" element={<Singaporevip />} />
    <Route path="/taiwanvip" element={<Taiwanvip />} />
    <Route path="/ruamlotto" element={<Ruamthai />} />
    <Route path="/ruamtang" element={<Ruamtang/>} />
    <Route path="/ruamhun" element={<Ruamhun/>} />
    




    </Routes>
    </BrowserRouter >
    <br></br>
    <Footer/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
