import React, {useState , useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, Grid, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import moment from'moment';
import 'moment/locale/th';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

theme.typography.h5 = {
  fontSize: '0.5rem',
  '@media (min-width:500px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};
theme.typography.h6 = {
  fontSize: '1rem',
  '@media (min-width:500px)': {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};
    

export default function Goverment() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#5D8BF4',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

    
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetch("https://baanpholhuayapi.herokuapp.com/hungmor")
          .then(res => res.json())
          .then(
            (result) => {
              setItems(result);
            },
          )
      }, [])
      const date = items.map(x => x.p_date);
      const threetop = items.map(x => x.p_threetop);
      const twobot = items.map(x => x.p_twobottom);
  return (
    <ThemeProvider theme={theme}>
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ p: 0 }}>
            <Paper sx={ {p:1} }>
                <Paper sx={ {p:1} }>

            <Container maxWidth="lg" sx={{ p:1 }}>
            <Box  sx={{ bgcolor: '#051367', height: '50vh', p:1 }} >
              <Box  sx={{ bgcolor: '#DFF6FF', height: '47vh', p:1 }} >
              <Grid container spacing={2} sx={{p:1}}>
              <Grid item xs={12}>
        <Item>
        <Typography variant="h5" style={{color:'#F7F5F2'}}>
         ผล หวยหุ้นฮั่งเส็งเช้า
        </Typography>
        </Item>
      </Grid>
      <Grid item xs={7} >
        <Item>
        <Typography variant="h5" style={{color:'#F7F5F2'}}>
          ประจำงวดวันที่
        </Typography>

        </Item>
      </Grid>
      
      <Grid item xs={5}  sx={{p:3}}>

        <Item>
        <Typography variant="h5" style={{color:'#F7F5F2'}}>
        {moment(date[0]).add(543,'y').format('Do MMMM YYYY')}
        </Typography>
        </Item>
      </Grid>
      
      <Grid item xs={6}>
        <Item>
        <Typography variant="h5" style={{color:'#F7F5F2'}}>
         เลข 3 ตัวบน
        </Typography>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
        <Typography variant="h5" style={{color:'#F7F5F2'}}>
         2 ตัวล่าง
        </Typography>
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item variant="h5" style={{color:'#FFD124'}}>{threetop[0]}</Item>
      </Grid>
      <Grid item xs={6}>
        <Item variant="h5" style={{color:'#FFD124'}}>{twobot[0]}</Item>
      </Grid>
    </Grid>
            </Box>
            </Box>

        </Container>
        </Paper>
        
            </Paper>
      </Container>
      <Container maxWidth="lg" sx={{ p: 3 }}>
          <Paper sx={ {p:2} }>
          <Box display="flex">
                <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6" style={{color:'#0093AB'}} gutterBottom component="div">
                        สถิติหวยหุ้นฮั่งเส็งเช้า ย้อนหลัง
                    </Typography>
                </Box>
                    <Box>
                    <Box sx={{ flexGrow: 1 }}>
                    
                        </Box>
                </Box>
          </Box>
          <TableContainer component={Paper}>
              
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">งวดวันที่</TableCell>
            <TableCell align="center">3 ตัวบน</TableCell>
            <TableCell align="center">2 ตัวล่าง</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center">{moment(row.p_date).add(543,'y').format('Do MMMM YYYY')}</TableCell>
              <TableCell align="center">{row.p_threetop}</TableCell>
              <TableCell align="center">{row.p_twobottom}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          </Paper>
      </Container>
    </React.Fragment>
    </ThemeProvider>
  );
}
