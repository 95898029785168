import React, {useState , useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BrowserRouter ,Routes, Route } from "react-router-dom";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1rem',
  '@media (min-width:450px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

theme.typography.h1 = {
  fontSize: '0.1rem',
  '@media (min-width:450px)': {
    fontSize: '0.1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#5D8BF4',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));




function App() {
  return (
    <ThemeProvider theme={theme}>
    <main>
    <Container maxWidth="lg" sx={{  bgcolor: '#EFFFFD', p:2 }}>
      <Grid>
      <a href="https://check.lottotoday.top/">
      <h1>LOTTO TODAY (ล็อตโต้ทูเดย์) เล่นหวยออนไลน์ ดูผลหวย</h1>
      </a>
      <p>LOTTO TODAY (ล็อตโต้ทูเดย์) เล่นหวยง่าย เร็ว สะดวก พร้อมดูผลหวยเรียลไทม์ ผลหวยล่าสุดอัพเดตทันที ตามเวลาหวยออก พร้อมกับบริการรับแทงหวยผ่านไลน์ เพียงแค่แอดไลน์ <a href="https://page.line.me/?accountId=074nsnvc&fbclid=IwAR1fSRQnZ9OKs4g75nTC2m6l2oR6QhPsoa22YrP5RMlIMO7qbWfTkhGxKiM">@074nsnvc</a>  แจ้งโพยได้หลากหลายวิธี ไม่ว่าจะพิมพ์ส่งหรือถ่ายรูปจากโพยให้พนักงาน และโอนยอดตามบิล เสร็จแล้วก็รอลุ้นผลได้เลย</p>
      <h1>LOTTO TODAY (ล็อตโต้ทูเดย์) แทงหวยออนไลน์ ซื้อหวยออนไลน์ อัตราจ่ายสูง บาทละ 95</h1>
      <h1>หวยครบ มั่นคง ปลอดภัย 100% ขั้นต่ำ 5 บาท</h1>
      <p>แทงหวย 3 ตัวตรง บาทละ 850, แทงหวย 3 ตัวโต๊ด จ่ายบาทละ 150, เล่นหวย 2 ตัวบน และเล่นหวย 2 ตัวล่าง จ่ายบาทละ 94 แทงขั้นต่ำเพียง 1 บาท</p>
      <ul>
        <li><a href="https://check.lottotoday.top/goverment">หวยรัฐบาลสลากกินแบ่งรัฐบาล</a> <a href="https://page.line.me/?accountId=074nsnvc&fbclid=IwAR1fSRQnZ9OKs4g75nTC2m6l2oR6QhPsoa22YrP5RMlIMO7qbWfTkhGxKiM">ซื้อหวยใต้ดิน เล่นหวยใต้ดิน</a>  <a href="https://check.lottotoday.top/goverment"> ดูผลหวยรัฐบาล</a> <a href="https://check.lottotoday.top/Gsb3y">ดูผลหวยออมสิน</a>  ดูผลหวย ธ.ก.ส. ออกผลทุกๆ วันที่ 1 กับ 16 ของทุกเดือน อาจมีการเลื่อนวันออกไปตามวันสำคัญ เปิดรับหวยรัฐบาล หวยใต้ดิน แทงออนไลน์ รับยอดตลอดเวลาก่อนหวยปิด 30 นาที</li>
        <li>หวยลาว ຫວຍລາວ<a href="https://check.lottotoday.top/laosdev"> หวยลาวพัฒนา</a> <a href="https://check.lottotoday.top/laoshd">หวยลาว HD</a> <a href="https://check.lottotoday.top/laostars">หวยลาวสตาร์</a>  <a href="https://check.lottotoday.top/laochampasak">หวยลาวจำปาสัก</a> <a href="https://check.lottotoday.top/laosamac"> หวยลาวสามัคคี</a>  <a href="https://check.lottotoday.top/laovip">หวยลาว VIP</a> <a href="https://check.lottotoday.top/laostarsvip">หวยลาวสตาร์ VIP</a> <a href="https://check.lottotoday.top/laoviangjan">หวยลาวเวียงจันทน์</a> <a href="https://check.lottotoday.top/laogachad">หวยลาวกาชาด</a> ออกผลทุกวันจันทร์กับวันพฤหัสบดี ผลหวยลาวจะออกเวลา 20.30 น.ตามเวลาไทย ปิดรับแทงหวย 20:00 น. มีเปิดรับ4ตัวบนจ่าย7,000บาท ผลออกจากสปป.ลาวโดยตรง</li>
        <li>หวยฮานอย หวยเวียดนาม เป็นหวยที่ออกทุกวัน มีทั้งหวย <a href="https://check.lottotoday.top/vtsim">ฮานอยปกติ</a>(ออกผลเวลา18:00-18:30) ,<a href="https://check.lottotoday.top/vtpi">หวยฮานอยพิเศษ</a>(ออกผลเวลา17:00-17:30),<a href="https://check.lottotoday.top/vtvip">หวยฮานอยVIP</a>(ออกผลเวลา19:00-19:30) นอกจากนี้ยังมี หวยฮานอยพิเศษ ฮานอยปกติ หวยฮานอย VIP <a href="https://check.lottotoday.top/vtstars">หวยฮานอยสตาร์</a> <a href="https://check.lottotoday.top/vttv">หวยฮานอยทีวี</a> <a href="https://check.lottotoday.top/vtredcross">หวยฮานอยกาชาด</a> <a href="https://check.lottotoday.top/vthd">หวยฮานอย HD</a> <a href="https://check.lottotoday.top/vtchn">ฮานอยตรุษจีน</a> <a href="https://check.lottotoday.top/vtdev">หวยฮานอยพัฒนา</a> <a href="https://check.lottotoday.top/vtsamac">หวยฮานอยสามัคคี</a> <a href="https://check.lottotoday.top/vtnight">หวยฮานอยดึก</a> <a href="https://check.lottotoday.top/vtextra">หวยฮานอย Extra</a> สามารถเล่นได้ทุกวัน ลุ้นหวยฮานอยได้หลายเวลาและลุ้นได้หลายรอบ</li>
        <li><a href="https://check.lottotoday.top/malay">หวยมาเลย์</a> หวยมาเลย์ เป็นหวยที่ถูกต้องตามกฎหมายของประเทศ การออกผลรางวัลรัฐบาลจะเป็นผู้ควบคุมการออกรางวัล ทำให้เป็นที่นิยมกันในคอหวย หวยมาเลย์ออกทุก วันพุธ วันเสาร์ และวันอาทิตย์ หวยมาเลย์ จะออกผลประมาณ 18:30 น. หรือ หวยมาเลย์ ออกผล 6 โมงครึ่ง</li>
        <li>หวยหุ้น หุ้นต่างประเทศ ออกผลทุกวันจันทร์-วันศุกร์ หุ้นบางตัวมีออกทั้งเช้าบ่าย มีทั้ง หุ้นไทย, หุ้นนิคเคอิ(เช้า-บ่าย), หุ้นฮั่งเส็ง(เช้า-บ่าย), หุ้นจีน(เช้า-บ่าย), หุ้นดาวโจนส์, หุ้นไต้หวัน, หุ้นเกาหลี, หุ้นสิงคโปร์, หุ้นอินเดีย, หุ้นอียิปต์ และหุ้นรัสเซีย, หุ้นอังกฤษ, หุ้นเยอรมัน หรือที่เรียกว่าหุ้น 3 รัฐ เวลาในการออกผลหวยและปิดรับแทงหวยก็จะแตกต่างกันออกไปในแต่ละหุ้น</li>
        <li>หวยออมสิน สลากออมสิน สลากออมสินพิเศษ จะออกทุกวันที่ 1 จะอ้างอิงจากผลสลากออมสินพิเศษ 5 ปี วันที่16 จะอ้างอิงจากผลสลากออมสินพิเศษ 3 ปี ผลของหวยออมสินจะอ้างอิง liveสด ของธนาคารออมสินโดยตรง เวลาปิดรับแทงหวยออมสิน 12:30 น.</li>
        <li>หวย ธ.ก.ส. ลสลากออมทรัพย์ของธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (ธ.ก.ส.) หรือ หวยธ.ก.ส. ออกรางวัลในวันที่ 16 ของเดือน และวันที่ 17 มกราคม ของทุกปีโดย ธ.ก.ส. ให้สำนักงานสลากกินแบ่งรัฐบาลเป็นผู้ดำเนินการออกรางวัลสลากออมทรัพย์ ปิดรับแทงหวยธ.ก.ส. 09:00 น.</li>
      </ul>
      </Grid>
    </Container>
    
</main>
</ThemeProvider>
  );
}

export default App;