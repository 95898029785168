
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Ticker from 'react-ticker'


const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#890F0D',
      darker: '#053e85',
    },
    neutral: {
      main: '#FFD93D',
      contrastText: '#053e85',
    },
  },
});



function App() {
  return (
    <ThemeProvider theme={theme}>
    <Paper
    sx={{
      position: 'relative',
      backgroundColor: 'grey.800',
      color: '#fff',
      mb: 1,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(https://browsecat.net/sites/default/files/black-1080-wallpapers-100791-259388-2606920.png)`,
    }}
  >
    {/* Increase the priority of the hero background image */}
    {<img style={{ display: 'none' }} src='https://browsecat.net/sites/default/files/black-1080-wallpapers-100791-259388-2606920.png 'alt='' />}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
      }}
    />
     <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          m: 1,
          p: 1,
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#383838' : '#00092C'),
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          border: '2px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
        }}
      >
         <Typography variant="p" sx={{ display: 'flex'}}   style={{color:'#FEB139'}} >
         🔽🔽 |||📢 ประกาศจากทางเว็ปไซต์ lottotoday.top 📢|||  🔽🔽
      </Typography>
      
      
      </Box>
      
      <Ticker>
        {() => (
            <>
                <Typography variant="p" sx={{ display: 'flex'}}   style={{color:'#FEB139'}} >
                ||| 📢 ประกาศจากทางเว็ปไซต์ ||| คลิ้กเมนูผลหวยแต่ละชนิด เพื่อดูผลหวย
      </Typography>
            </>
        )}
    </Ticker>
    <Grid container>
      
      <Grid item md={6}>
        
        <Box
        
          sx={{
            position: 'relative',
            p: { xs: 3, md: 5 },
            pr: { md: 0 },
          }}
        >
          
            <Typography variant="h5" color="inherit" paragraph>
            ยินดีต้อนรับเข้าสู่เว็ปไวต์ตรวจผลหวย lottotoday.top
          </Typography>
           <Stack direction="row" spacing={2}> 
      <Button href="" variant="contained" color="neutral">
        ซื้อหวยออนไลน์
        </Button>
        <Button href="" variant="contained" color="neutral">
        เข้าเล่นเกมส์
        </Button>
        <Button variant="contained" color="neutral">
        คำนวนหวย
      </Button>
      
    </Stack>
          <Link variant="subtitle1" href="#">
          </Link>
        </Box>
        
      </Grid>
      

    </Grid>
    
    
  </Paper>



 
  </ThemeProvider>
  
  );
}

export default App;