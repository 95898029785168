import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Button } from '@mui/material';


function Copyright() {
  return (
    <Typography variant="body2" color="text.primary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Lotto to day
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer(props) {
  const { description, title } = props;

  return (
    <Box component="footer" sx={{ bgcolor: '#2E9BDD', py: 1  }}>
      <Container maxWidth="lg">
      <img align="center"
    src={`https://sv1.picz.in.th/images/2022/07/14/VeRcye.png`}
      />
      <img align="center"
    src={`https://sv1.picz.in.th/images/2022/07/14/VeRcye.png`}
      />
      <img align="center"
    src={`https://sv1.picz.in.th/images/2022/07/14/VeRcye.png`}
      />
      <img align="center"
    src={`https://sv1.picz.in.th/images/2022/07/14/VeRcye.png`}
      />
      <img align="center"
    src={`https://sv1.picz.in.th/images/2022/07/14/VeRcye.png`}
      />
      <img align="center"
    src={`https://sv1.picz.in.th/images/2022/07/14/VeRcye.png`}
      />
      
       
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button href="/"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                หน้าหลัก
              </Button>
              <Button href="/ruamlotto"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยไทย
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยลาว
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยลาวพัฒนา
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยลาวสตาร์
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยลาวกาชาด  
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยเวียดนาม
              </Button>
              
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยมาเลเซีย
              </Button>
              <Button href="/ruamhun"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยหุ้น
              </Button>
              <Button href="/ruamhun"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยหุ้น VIP
              </Button>
          </Box>

        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          {description}
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;