import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Paper, Grid, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import ReactCountryFlag from "react-country-flag"
import 'moment/locale/th';

const str = new Date().toLocaleDateString('th', { timeZone: 'Africa/Cairo' });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function Goverment() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#5D8BF4',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const handleSubmit = event => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "p_date": p_date,
      "p_one": p_one,
      "p_threefront": p_threefront,
      "p_threeback": p_threeback,
      "p_threetop": p_threetop,
      "p_twotop": p_twotop,
      "p_twobottom": p_twobottom,
      "lotto_id": "TH",
      "lotto_name": "THGV"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://baanpholhuayapi.herokuapp.com/goverment/add", requestOptions)
      .then(response => response.json())
      .then(result => {
        alert(result['status'])
        if (result['status'] === 'OK') {
          window.location.href = '/goverment'
        }
      })
      .catch(error => console.log('error', error));
  }
  const [p_date, setP_date] = useState('')
  const [p_one, setP_one] = useState('')
  const [p_threefront, setP_threefront] = useState('')
  const [p_threeback, setP_threeback] = useState('')
  const [p_threetop, setP_threetop] = useState('')
  const [p_twotop, setP_twotop] = useState('')
  const [p_twobottom, setP_twobottom] = useState('')
  const [lotto_id, setLotto_id] = useState('')
  const [lotto_name, setLotto_name] = useState('')

  const [items, setItems] = useState([]);
  useEffect(() => {
    fetch("https://baanpholhuayapi.herokuapp.com/ruam/goverment")
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
      )
  }, [])

  function createData(name, two_buttom, three_top) {
    return { name, two_buttom, three_top };
  }

  var icon = ['https://flagcdn.com/16x12/za.png'];

  const wait = 'XXX'

  const rows = [
    createData(' รัฐบาลไทย', wait, wait),
    createData(' หวยออมสินพิเศษ 3 ปี', wait, wait),
    createData(' หวยออมสินพิเศษ 5 ปี ', wait, wait),
  ];
  const rows_lao = [
    createData(' ลาว พัฒนา', wait, wait),
    createData(' ลาว เอ็กซ์ตร้า', wait, wait),
    createData(' ลาว ทีวี ', wait, wait),
    createData(' ลาว พิเศษ ', wait, wait),
    createData(' ลาว HD', wait, wait),
    createData(' ลาว สตาร์', wait, wait),
    createData(' ลาวจำปาสัก ', wait, wait),
    createData(' ลาวสามัคคี', wait, wait),
    createData(' ลาว วีไอพี ', wait, wait),
    createData(' ลาว สตาร์วีไอพี ', wait, wait),
    createData(' ลาวเวียงจันทน์ ', wait, wait),
    createData(' ลาวกาชาด ', wait, wait),
    createData(' มาเลเซีย ', wait, wait),
  ];
  const rows_malay = [
    createData(' มาเลเซีย', wait, wait),
  ];
  const rows_vt = [
    createData(' ฮานอยพิเศษ', wait, wait),
    createData(' ฮานอยปกติ', wait, wait),
    createData(' ฮานอย VIP', wait, wait),
    createData(' ฮานอยสตาร์', wait, wait),
    createData(' ฮานอย TV', wait, wait),
    createData(' ฮานอยกาชาด', wait, wait),
    createData(' ฮานอย HD', wait, wait),
    createData(' ฮานอยพัฒนา', wait, wait),
    createData(' ฮานอยสามัคคี', wait, wait),
    createData(' ฮานอยดึก', wait, wait),
    createData(' ฮานอย Extra', wait, wait),
  ];

  const rows_hun = [
    createData(' หุ้นดาวโจนส์', wait, wait),
    createData('หุ้นนิเคอิ รอบเช้า', wait, wait),
    createData('หุ้นจีน รอบเช้า ', wait, wait),
    createData('หุ้นฮั่งเส็ง รอบเช้า ', wait, wait),
    createData('หุ้นไต้หวัน', wait, wait),
    createData('หุ้นเกาหลี', wait, wait),
    createData('หุ้นนิเคอิ รอบบ่าย ', wait, wait),
    createData('หุ้นจีน รอบบ่าย ', wait, wait),
    createData('หุ้นฮั่งเส็ง รอบบ่าย ', wait, wait),
    createData('หุ้นสิงคโปร์ ', wait, wait),
    createData('หุ้นไทย ปิดเย็น ', wait, wait),
    createData('หุ้นอินเดีย ', wait, wait),
    createData('หุ้นอิยิปต์ ', wait, wait),
    createData('หุ้นรัสเซีย', wait, wait),
    createData('หุ้นเยอรมัน', wait, wait),
    createData('หุ้นอังกฤษ ', wait, wait),
  ];
  const rows_hunvip = [
    createData('หุ้นดาวโจนส์ VIP', wait, wait),
    createData('หุ้นนิเคอิ รอบเช้า VIP', wait, wait),
    createData('หุ้นจีน รอบเช้า VIP', wait, wait),
    createData('หุ้นฮั่งเส็ง รอบเช้า VIP', wait, wait),
    createData('หุ้นไต้หวัน VIP', wait, wait),
    createData('หุ้นเกาหลี VIP', wait, wait),
    createData('หุ้นนิเคอิ รอบบ่าย VIP ', wait, wait),
    createData('หุ้นจีน รอบบ่าย VIP ', wait, wait),
    createData('หุ้นฮั่งเส็ง รอบบ่าย VIP', wait, wait),
    createData('หุ้นสิงคโปร์ VIP', wait, wait),
    createData('หุ้นรัสเซีย VIP', wait, wait),
    createData('หุ้นเยอรมัน VIP', wait, wait),
    createData('หุ้นอังกฤษ VIP ', wait, wait),
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ p: 1 }}>

        <Paper sx={{ p: 2, bgcolor: '#06113C' }}>
          <Box display="flex">
            <Box sx={{ flexGrow: 5, bgcolor: '#FEB139' }}>

              <Typography sx={{ p: 1 }} variant="h6" gutterBottom component="div">
              🌟ผลหวยรัฐบาลไทย , หวยออมสินพิเศษ 3 ปี , หวยออมสินพิเศษ 5 ปี ประจำวันที่ {str}
              </Typography>
            
            </Box>
            <Box>
              <Box sx={{ flexGrow: 1 }}>

              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="30%" align="center">หวย</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สามตัวบน</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สองตัวล่าง</StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((rows) => (
                  <StyledTableRow key={rows.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                    <ReactCountryFlag countryCode="TH" svg style={{ width: '1.5em',height: '1.5em',}}title="TH"/>
                    {rows.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{rows.three_top}</StyledTableCell>
                    <StyledTableCell align="center">{rows.two_buttom}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <Paper sx={{ p: 2, bgcolor: '#06113C' }}>

          <Box display="flex">
            <Box sx={{ flexGrow: 5, bgcolor: '#FEB139' }}>
              <Typography sx={{ p: 1 }} variant="h6" gutterBottom component="div">
                🌟ผลหวยมาเลเซีย ประจำวันที่ {str}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ flexGrow: 1 }}>

              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="30%" align="center" >หวย</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สามตัวบน</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สองตัวล่าง</StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows_malay.map((rows_malay) => (
                  <StyledTableRow key={rows_malay.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                    <ReactCountryFlag countryCode="MY" svg style={{ width: '1.5em',height: '1.5em',}}title="MY"/>
                      {rows_malay.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{rows_malay.three_top}</StyledTableCell>
                    <StyledTableCell align="center">{rows_malay.two_buttom}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <Paper sx={{ p: 2, bgcolor: '#06113C' }}>

          <Box display="flex">
            <Box sx={{ flexGrow: 5, bgcolor: '#FEB139' }}>
              <Typography sx={{ p: 1 }} variant="h6" gutterBottom component="div">
                🌟ผลหวยเวียดนาม ประจำวันที่ {str}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ flexGrow: 1 }}>

              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="30%" align="center" >หวย</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สามตัวบน</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สองตัวล่าง</StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows_vt.map((rows_vt) => (
                  <StyledTableRow key={rows_vt.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                    <ReactCountryFlag countryCode="VN" svg style={{ width: '1.5em',height: '1.5em',}}title="VT"/>
                      {rows_vt.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{rows_vt.three_top}</StyledTableCell>
                    <StyledTableCell align="center">{rows_vt.two_buttom}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <Paper sx={{ p: 2, bgcolor: '#06113C' }}>

          <Box display="flex">
            <Box sx={{ flexGrow: 5, bgcolor: '#FEB139' }}>
              <Typography sx={{ p: 1 }} variant="h6" gutterBottom component="div">
                🌟ผลหวยลาว ประจำวันที่ {str}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ flexGrow: 1 }}>

              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="30%" align="center" >หวย</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สามตัวบน</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สองตัวล่าง</StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows_lao.map((rows_lao) => (
                  <StyledTableRow key={rows_lao.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                    <ReactCountryFlag countryCode="LA" svg style={{ width: '1.5em',height: '1.5em',}} title="LA"/>
                      {rows_lao.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{rows_lao.three_top}</StyledTableCell>
                    <StyledTableCell align="center">{rows_lao.two_buttom}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <Paper sx={{ p: 2, bgcolor: '#06113C' }}>
          <Box display="flex">
            <Box sx={{ flexGrow: 5, bgcolor: '#FEB139' }}>
              <Typography sx={{ p: 1 }} variant="h6" gutterBottom component="div">
                🌟ผลหวยหุ้น ประจำวันที่ {str}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ flexGrow: 1 }}>

              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="30%" align="center">หวย</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สามตัวบน</StyledTableCell>
                  <StyledTableCell  width="10%" align="center">สองตัวล่าง</StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows_hun.map((rows_hun) => (
                  <StyledTableRow key={rows_hun.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {rows_hun.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{rows_hun.three_top}</StyledTableCell>
                    <StyledTableCell align="center">{rows_hun.two_buttom}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <Paper sx={{ p: 2, bgcolor: '#06113C' }}>
          <Box display="flex">
            <Box sx={{ flexGrow: 5, bgcolor: '#FEB139' }}>
              <Typography sx={{ p: 1 }} variant="h6" gutterBottom component="div">
                🌟 ผลหวยหุ้น VIP ประจำวันที่ {str}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ flexGrow: 1 }}>

              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="30%" align="center">หวย</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สามตัวบน</StyledTableCell>
                  <StyledTableCell width="10%" align="center">สองตัวล่าง</StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {rows_hunvip.map((rows_hunvip) => (
                  <StyledTableRow key={rows_hunvip.name}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {rows_hunvip.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{rows_hunvip.three_top}</StyledTableCell>
                    <StyledTableCell align="center">{rows_hunvip.two_buttom}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <Stack>
          <Button href='/goverment' variant="contained" color="primary">
            ตรวจสอบผลหวยรัฐบาลไทย ย้อนหลัง
          </Button>
          <Button href='/Gsb3y' variant="contained" color="secondary">
            ตรวจสอบผลหวยสลากออมสิน 3 ปี ย้อนหลัง
          </Button>
          <Button href='/Gsb5y' variant="contained" color="primary">
            ตรวจสอบผลหวยสลากออมสิน 5 ปี ย้อนหลัง
          </Button>
        </Stack>

      </Container>
    </React.Fragment>
  );
}
