import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import '../App.css';
import date from 'date-and-time';

const str = new Date().toLocaleDateString('th', { timeZone: 'Africa/Cairo' });
console.log(str)


const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/">
                หน้าหลัก
              </Button>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/ruamlotto">
                ตรวจสอบผลหวยไทย
              </Button>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/ruamtang">
                ตรวจสอบผลหวยลาว
              </Button>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/ruamtang">
                ตรวจสอบผลหวยเวียดนาม
              </Button>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/malay">
                ตรวจสอบผลหวยมาเลเซีย
              </Button>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/ruamhun">
                ตรวจสอบผลหวยหุ้น
              </Button>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu}>
                <Button sx={{ color: '#F8B400',}} href="/ruamhun">
                ตรวจสอบผลหวยหุ้น VIP
              </Button>
                </MenuItem>
            </Menu>
          </Box>
        <Box sx={{ flexGrow: 1 }}>
        <ImageList sx={{ width: 100, height: 100 }}  cols={1} rowHeight={5}>
        <a href="/">
<ImageListItem >
 
  <img 
    src={`https://sv1.picz.in.th/images/2022/07/14/VegGAz.png`}
      />
     
         </ImageListItem>
         </a>
        </ImageList>
    </Box>
     
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button href="/"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                หน้าหลัก
              </Button>
         
              <Button href="/ruamlotto"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยไทย
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยลาว
              </Button>
              <Button href="/ruamtang"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยเวียดนาม
              </Button>
              <Button href="/malay"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยมาเลเซีย
              </Button>
              <Button href="/ruamhun"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยหุ้น
              </Button>
              <Button href="/ruamhun"
                sx={{ my: 2, color: '#F8B400', display: 'block' }}
              >
                ดูผลหวยหุ้น VIP
              </Button>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
          <Box
      >
        
      <Typography variant="h7" sx={{ display: 'flex'}}   style={{color:'#FFFFF'}} >
           {str}
           </Typography>
      
      
      </Box>

          </Box>
        </Toolbar>
      </Container>
      
    </AppBar>
  );
};
export default ResponsiveAppBar;
